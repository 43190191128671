<script>
    import Layout from "@/views/layouts/static/main";
    import PageHeader from "@/components/static/page-header";
    import {mapState} from "vuex";
    import appConfig from "@/app.config";
    import { FormWizard, TabContent } from "vue-form-wizard";
    import "vue-form-wizard/dist/vue-form-wizard.min.css";
    import createForm from "./components/createForm.vue";
    import { required } from 'vuelidate/lib/validators';
    
    export default {
        page: {
            title: "Create Weekly Shifts",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        components: {
            Layout,
            PageHeader,
            FormWizard,
            TabContent,
            createForm
        },
         computed: {
             ...mapState({
            
        }),

    },
        
        data() {
            return {
                title: "Create Weekly Shift ",
                items: [
                    {
                        text: "Shift",
                    },
                    {
                        text: "Create Weekly Shift",
                        active: true,
                    },
                ],
                days_data:[],
                weekly_shifts:[],
                days: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday","Saturday"],                
                location_id:null,
                shift_week_days:[{start:"Monday",end:"Saturday"},{start:"Tuesday",end:"Monday"}],
                shift_week_day:null,
                loadingWizard: false,
                locations:[],
                checkedDays : [],
            };
        },
        validations: {
                    
                 location_id:{
                     required,
                 },
                 shift_week_day:{
                     required,
                 }
                    },

        mounted(){
            // this.buildData()
            this.fetchLocations();
            // this.locations = this.$store.state.auth.currentUser.employee.locations;
       
        },

        methods: {
            fetchLocations() {
                this.$axios.get("/locations").then((response) => {
                    let data = response.data;
                    this.locations = data.payload;
                }).catch(error => {
                    this.handleErrorResponse(error.response, "error");
                }).finally(function () {
                });
            },
            buildData(){
                this.checkedDays = [];
                this.days_data = [];
                this.weekly_shifts = [];
                this.days.map((day)=>{
                    if(this.shift_week_day.start == day){
                        this.days_data.push(day);
                    }
                })
                this.days.map((day)=>{
                    if(this.shift_week_day.start != day && this.shift_week_day.end != day ){
                        this.days_data.push(day);
                    }
                })
                this.days.map((day)=>{
                    if(this.shift_week_day.end==day){
                        this.days_data.push(day);
                    }
                })
                for(let i=0;i<this.days_data.length;i++){
                    const roles= [{
                        id:null,
                        count:null,
                        role_count:null,
                        remaining_slots:null,
                    }];
                    const shift = { 
                        shift_type:'Non_Premium',
                        bonus:null,
                        name:null,
                        day: null,
                        chunk: null,
                        label_color: '#0a0a0a',
                        from: null,
                        to: null,
                        desc: null,
                        shifttimebreak:[],
                    };
                    this.weekly_shifts.push({name: this.days_data[i], roles: roles, shift: shift});
                    this.weekly_shifts[i].shift.day = this.days_data[i];
                }
                this.weekly_shifts.map((shift)=>{
                    this.checkedDays.push(shift);
                });
            },
            onComplete: function(){
      
                  let self = this;
                if (self.loadingWizard) {
                return;
                }
                    
                self.loadingWizard = true;

                self.checkedDays.forEach((day) => {
                    // day.shift.from = self.changeDateTimeToUTCZero(day.shift.from);
                    // day.shift.to = self.changeDateTimeToUTCZero(day.shift.to);

                    //utc_from/to is to save time in shift area roles.
                    day.shift.utc_from =self.changeDateTimeToUTCZero(day.shift.from);
                    day.shift.utc_to = self.changeDateTimeToUTCZero(day.shift.to);
                });

                self.$axios.post('/shift/store-weekly-shift', { shifts: self.checkedDays })
                .then(response => {
                    let data = response.data;

                    self.triggerSwal(data.message, "success");
                    
                  self.$router.push("/shifts");
                  
                })
                .catch(error => {
                   
                    self.handleErrorResponse(error.response, "error");

                    
                })
                .finally(function () {
                    self.loadingWizard = false;
                });
             },

            setLoading: function(value) {
                this.loadingWizard = value
            },
            handleValidation: function(isValid, tabIndex){
                console.log('Tab: '+tabIndex+ ' valid: '+isValid)
            },
            validateLocation() {
                if(this.$refs.locationForm){
                    if (this.$v.location_id.$invalid === true || this.$v.shift_week_day.$invalid === true ) 
                    {
                        this.triggerSwal("Location and shift days fields are required", "error");
                        return false;
                       }else {
                        return true
                       }
                }
                
            },
            validateCreateShiftComponents(tab_name,index) {
                // console.log(tab_name,this.getDayname(this.$refs.createForm[index].$v.shift.from.$model))
                // console.log(this.$refs.createForm[index].$v.shift.from.$model)
                if(tab_name!=this.getDayname(this.$refs.createForm[index].$v.shift.from.$model)){
                    this.triggerSwal("please select date according to day", "error");
                    return false;
                }
                    if (this.$refs.createForm[index].$v.shift.name.$invalid === true ||
                    // this.$refs.createForm[index].$v.shift.chunk.$invalid === true ||
                    // this.$refs.createForm[index].$v.shift.desc.$invalid === true ||
                     this.$refs.createForm[index].$v.shift.from.$invalid === true || 
                     this.$refs.createForm[index].$v.shift.to.$invalid === true) {
                    
                         return false;
                    } else if(this.$refs.createForm[index].roles.length <= 0){
                        return false;
                    } else { 
                        this.$refs.createForm[index].roles.forEach(role => {
                            if(role.id == null) {
                                self.triggerSwal("Role is not Selected", "error");
                                return false;
                            } else if(role.count <= 0) {
                                self.triggerSwal("There Should be Atleast 1 Role Count", "error");
                                return false;
                            }
                        });

                        return true;
                    }        
            },

  },
        middleware: "authentication",
    };
</script>

<template>
    <Layout>
        <PageHeader :items="items" :title="title"/>
        <div class="row">
        <div class="card">
        <div class="card-body">
         <form-wizard @on-complete="onComplete"
                @on-loading="setLoading"
                @on-validate="handleValidation"
                shape="circle"
                color="#70C213 " stepSize="xs" class="px-0">
                 
                <tab-content key="0" title="Select Location"
                            :before-change="() => validateLocation()"
                            class="px-0">
                            <template >
                            <form ref="locationForm">
                                <div class="col-md-8 mx-auto mb-3">
                                    <div class="d-flex align-items-center gap-2">
                                        <div class="icon-wrapper mt-3 pt-1">
                                            <img src="@/assets/images/profile-manager/icon-11.png" width="21" alt="" />
                                        </div>
                                        <div class="form-field__wrapper w-100">
                                           
                                            <label>Location</label>
                                            
                                            <select v-model="location_id" id="emp_job_type" class="form-select" 
                                            >
                                            <option :value="null" v-if="locations.length == 0" disabled>No Location Found</option>
                                            <option :value="null" v-else disabled>Select Location</option>
                                                <option :value="location.id" v-for="(location,index) in locations" :key="index" >{{location.name}}</option>
                                            </select>
                                           
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-8 mx-auto mb-3">
                                    <div class="d-flex align-items-center gap-2">
                                        <div class="icon-wrapper mt-3 pt-1">
                                            <img src="@/assets/images/profile-manager/icon-11.png" width="21" alt="" />
                                        </div>
                                        <div class="form-field__wrapper w-100">
                                            
                                            <label>Shift week days</label>
                                            
                                            <select v-model="shift_week_day" id="shift_week" @change="buildData()" class="form-select" 
                                            >
                                            <option :value="shift_week_day" v-for="(shift_week_day,index) in shift_week_days" :key="index" >{{shift_week_day.start}} - {{shift_week_day.end}}</option>
                                              
                                            </select>
                                        </div>
                                    </div>
                                    <div class="px-4 py-4">
                                        <div class="row">
                                        <h6 class="text-muted" v-if="weekly_shifts.length >0">Select Days in a Week</h6>
                                            <template class="form-field__wrapper w-100"  >
                                                <div class="col-md-4" v-for="(tab,key) in weekly_shifts" :key="key+1">
                                                    <input 
                                                        class="form-check-input"
                                                        type="checkbox"
                                                        :value="tab"
                                                        v-model="checkedDays"
                                                        :checked="true"
                                                    /> {{tab.name}}
                                                </div>
                                            </template>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            </template>
                </tab-content>
                <template v-for="(tab,key) in checkedDays">
                  
                <tab-content :key="key+1" :title="tab.name" :before-change="() => validateCreateShiftComponents(tab.name,key)" >
                    <create-form :roles="tab.roles" ref="createForm" :location_id="location_id" :shift="tab.shift"></create-form>
                </tab-content>
                </template>
            
      
      <div class="loader" v-if="loadingWizard"></div>
    </form-wizard>
        </div>
        </div>
        </div>
    </Layout>
</template>
